const validate = (values) => {
  const errors = {};

  if (values.symbol && values.symbol.touched) {
    if (!values.symbol.value) {
      errors.symbol = 'A symbol is required';
    }
  }
  if (values.description && values.description.touched) {
    if (!values.description.value) {
      errors.description = 'A description is required';
    }
  }
  return errors;
};

export default validate;
