import React from 'react';
import logo from './logo.svg';
import Ticker from './components/ticker';
import Chart from './components/chart';
import './App.css';

function App() {
  return (
    <div className="App">
      <section className="ticker-section">
        <Ticker />
      </section>
      <section className="chart-section">
        <Chart />
      </section>
    </div>
  );
}

export default App;
