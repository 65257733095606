import React, { useEffect, useState } from 'react';
import { useFormInput, useErrors, useSubmitReady } from '../../hooks/useForm';
import TickerItem from './tricker-item';
export default function TickerWidget(props) {
  const defaultSymbols = [{description:'AAPL',proName:'NASDAQ:AAPL'},{description:'AMD',proName:'NASDAQ:AMD'},{description:'DISNEY',proName:'NYSE:DIS'},{description:'NVDA',proName:'NASDAQ:NVDA'},{description:'SPY',proName:'AMEX:SPY'},{description:'BABA',proName:'NYSE:BABA'},{description:'MSFT',proName:'NASDAQ:MSFT'},{description:'QCOM',proName:'NASDAQ:QCOM'},{description:'TSLA',proName:'NASDAQ:TSLA'},{description:'SNAP',proName:'NYSE:SNAP'},{description:'TWTR',proName:'NYSE:TWTR'},{description:'AMZN',proName:'NASDAQ:AMZN'},{description:'NFLX',proName:'NASDAQ:NFLX'},{description:'USSTEEL',proName:'NYSE:X'},{description:'INTEL',proName:'NASDAQ:INTC'},{description:'BAC',proName:'NYSE:BAC'},{description:'SQ',proName:'NYSE:SQ'},{description:'JD',proName:'NASDAQ:JD'},{description:'CSCO',proName:'NASDAQ:CSCO'},{description:'Citi',proName:'NYSE:C'},{description:'Ford',proName:'NYSE:F'},{description:'GOOGL',proName:'NASDAQ:GOOGL'},{description:'BOEING',proName:'NYSE:BA'},{description:'ACTIVISION',proName:'NASDAQ:ATVI'},{description:'NIO',proName:'NYSE:NIO'},{description:'SPY',proName:'AMEX:SPY'},{description:'QQQ',proName:'NASDAQ:QQQ'},{description:'IWM',proName:'AMEX:IWM'},{description:'S&P500ENERGY',proName:'AMEX:XLE'},{description:'ULTA',proName:'NASDAQ:ULTA'}];
  function init() {
    try {
      return localStorage.getItem('symbols')
        ? JSON.parse(localStorage.getItem('symbols'))
        : defaultSymbols;
    } catch (error) {
      console.log(error);
      return defaultSymbols;
    }
  }

  // Inputs
  const symbol = useFormInput('');
  const description = useFormInput('');
  // Error handlers
  const symbolErrors = useErrors({ symbol: symbol.state });
  const descriptionErrors = useErrors({ description: description.state });
  // Sumbit handlers
  const submitReady = useSubmitReady({
    symbol: symbol.state,
    description: description.state
  });
  const [symbols, setSymbols] = useState(init());
  const handleAdd = e => {
    const newSymbols = [
      ...symbols,
      {
        description: `${description.state.value}`,
        proName: `${symbol.state.value}`
      }
    ];
    try {
      localStorage.setItem('symbols', JSON.stringify(newSymbols));
      setSymbols(newSymbols);
    } catch (error) {
      console.log(error);
      window.alert('ERROR!, try again');
    }
  };

  const handleDelete = () => {
    if (description.state.value || symbol.state.value) {
      const indexToDelete = symbols.reduce(
        (acc, curr, idx) =>
          curr.description === description.state.value ||
          curr.proName === symbol.state.value
            ? idx
            : acc,
        -1
      );
      if (indexToDelete !== -1) {
        const filteredSymbols = symbols.filter((el, i) => i !== indexToDelete);
        localStorage.setItem('symbols', JSON.stringify(filteredSymbols));
        setSymbols(filteredSymbols);
      } else {
        window.alert(
          `Delete failed! \n SYMBOL: "${
            symbol.state.value
          }"\n or\n DESCRIPTION: "${
            description.state.value
          }"\n <do></do>n't match any symbols on the ticker`
        );
      }
    } else {
      window.alert('Please insert at least a description');
    }
  };

  useEffect(() => {
    if (document.querySelector('.ticker-wrapper').children[1])
      document.querySelector('.ticker-wrapper').children[1].remove();
    const script = document.createElement('script');
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.setAttribute('async', true);
    script.type = 'text/javascript';
    script.innerHTML = ` {
      "symbols": ${JSON.stringify(symbols)},
      "colorTheme": "dark",
      "isTransparent": false,
      "displayMode": "compact",
      "locale": "en"
      }`;
    document.querySelector('.ticker-wrapper').appendChild(script);
  }, [symbols]);

  const resetAll = () => {
    try {
      localStorage.setItem(
        'symbols',
        JSON.stringify(defaultSymbols)
      );
    } catch (error) {
      console.log(error);
      window.alert('ERROR!, try again');
    }
    setSymbols(defaultSymbols);
  };

  function deleteItem(idx) {
    const filteredSymbols = symbols.filter((el, i) => i !== idx);
    localStorage.setItem('symbols', JSON.stringify(filteredSymbols));
    setSymbols(filteredSymbols);
  }

  return (
    <div className="ticker-container">
      <div className="ticker-wrapper">
        <div className="ticker-wrapperdescription">
          <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget" />
            <div className="tradingview-widget-copyright">
              <a
                href="https://www.tradingview.com"
                rel="noopener"
                target="_blank"
              >
                <span className="blue-text">Quotes</span>
              </a>{' '}
              by TradingView
            </div>
          </div>
        </div>
      </div>
      <h2 className="headline">Ticker symbols</h2>
      <div className="ticker-list">
        {symbols.map((el, i) => (
          <TickerItem {...el} idx={i} handleDelete={deleteItem} key={`symb-${i}`} />
        ))}
      </div>
      <form>
        <label className="label">Symbol -></label>
        <input
          type="text"
          value={symbol}
          required
          name="symbol"
          placeholder="EXCHANGE:SYMBOL"
          value={symbol.state.value}
          {...symbol.events}
          className={`input symbol ${symbolErrors.symbol && 'error'}`}
        />
        <label className="label">Description -></label>
        <input
          type="text"
          value={description}
          required
          name="description"
          value={description.state.value}
          {...description.events}
          className={`input description ${descriptionErrors.description &&
            'error'}`}
        />
        <button
          type="button"
          onClick={() => handleAdd()}
          disabled={!submitReady}
        >
          add
        </button>
        <button type="button" onClick={() => resetAll()}>
          Reset Ticker
        </button>
      </form>
    </div>
  );
}
