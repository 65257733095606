import React, { useEffect } from 'react';
export default function ChartWidget(props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = ` new TradingView.widget(
      {
      "width": 980,
      "height": 610,
      "symbol": "NASDAQ:AAPL",
      "interval": "D",
      "timezone": "America/Los_Angeles",
      "theme": "Dark",
      "style": "1",
      "locale": "en",
      "toolbar_bg": "#f1f3f6",
      "enable_publishing": false,
      "withdateranges": true,
      "hide_side_toolbar": false,
      "allow_symbol_change": true,
      "container_id": "tradingview_cd90b"
      }
      );`;
    document.querySelector('.chart-widget').appendChild(script);
  }, []);

  return (
    <div className="chart-widget">
      <div className="w-embed w-script">
        <div className="tradingview-widget-container">
          <div id="tradingview_cd90b" />
          <div className="tradingview-widget-copyright">
            <a
              href="https://www.tradingview.com/symbols/NASDAQ-AAPL/"
              rel="noopener"
              target="_blank"
            >
              <span className="blue-text">AAPL Chart</span>
            </a>{' '}
            by TradingView
          </div>
        </div>
      </div>
    </div>
  );
}
